import Image from "next/image";
import Link from "next/link";
import {
  AppBarMobile,
  AppBarWeb,
  ClientOnly,
  ColorBlockLogo,
  Footer,
} from "@shared/components";
import { useWindowSize } from "@dustlabs/web/hooks";

export default function Home() {
  const [_, winHeight] = useWindowSize();

  return (
    <ClientOnly>
      <div
        className="flex flex-col w-full transition-colors ease-in-out  
      duration-700 bg-lightBackground"
        style={{
          minHeight: winHeight,
        }}
      >
        {/* AB monitor header */}
        <AppBarWeb />
        {/* AB mobile header */}
        <AppBarMobile />
        <main className="w-full flex-1 flex flex-col md:mt-36 lg:mt-52 justify-center items-center h-fit md:h-screen pb-6 max-w-[1400px] mx-auto relative">
          <div className="flex flex-col gap-32 md:gap-52 justify-center items-center">
            <div className="flex flex-col items-center">
              <div className="flex flex-col gap-7">
                <div className="flex flex-col items-center justify-center">
                  <div className="flex text-9xl items-center justify-around h-full gap-1">
                    <span className="pt-7">4</span>
                    <span className="pt-7 y00ts:hidden">0</span>
                    <Image
                      src="/y00ts/y00ts-0.svg"
                      width={60}
                      height={60}
                      alt="logo"
                      className="hidden y00ts:block"
                    />
                    <span className="pt-7">4</span>
                  </div>
                  <div className="flex md:text-xl">something went wrong</div>
                </div>
                <span className="justify-center hidden y00ts:flex">
                  <ColorBlockLogo />
                </span>
              </div>
            </div>
            <Link
              href="/"
              className="cursor-pointer text-[#3366CC] text-2xl font-light"
            >
              back home
            </Link>
          </div>
        </main>

        {/* AB mobile footer */}
        {/* <Footer /> */}
      </div>
    </ClientOnly>
  );
}
